import React , {useState, useEffect} from 'react';
import Sale01 from '../components/sale/Sale01';
import Cookies from 'js-cookie';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageTitle from '../components/pagetitle';
import {Link} from 'react-router-dom';
import img from '../assets/images/avt/avt.png'



function UserProfile(props) {  
    const [myWalletAddress, setWalletAddress] = useState("");
   
    
    useEffect( () => {
        const sessionWallet = Cookies.get('wallet');
        setWalletAddress(sessionWallet.substring(0,10)+"....."+sessionWallet.substring(30));              
     },[])

    const [dataCoinTab] = useState([
        {
            id: 1,
            title: 'User Profile',
            icon: 'fa-user'
        },
        {
            id: 2,
            title: 'Referrals',
            icon: 'fa-share-nodes'
        },     
        {
            id: 5,
            title: 'Secret-key',
            icon: 'fa-lock'
        },

    ]);
    return (
        <div>


            <PageTitle heading='User Profile' title='User' />


            <section className="user-profile flat-tabs">
            <div className="container">
                <div className="row">
                <Tabs>
                    
                    <TabList>
                        <div className="user-info center">
                            <div className="avt">
                                <input
                                type="file"
                                className="custom-file-input"
                                id="imgInp"
                                required
                                />
                                <img id="blah" src={img} alt="no file" />
                            </div>
                            <h6 className="name">Display Name</h6>
                            <p className='mb-3'>Email</p>
                            <input type="text" className="form-control" value={myWalletAddress}  disabled/>
                        </div>
                        {
                            dataCoinTab.map(idx => (
                                <Tab key={idx.id}><h6 className="fs-16">
                                <i className={`fa ${idx.icon}`}></i>
                                {idx.title}
                                </h6></Tab>
                            ))
                        }

                    </TabList>

                    <TabPanel>
                        <div className="content-inner profile">
                            <form action="#">
                            <h4>User Profile</h4>
                            <h6>Information</h6>
                           
                            <div className="form-group d-flex s1">
                                <input type="text" className="form-control" value="Full Name" />
                                <input type="text" className="form-control" value="Display Name" />
                            </div>
                            <div className="form-group d-flex">                               
                                <input type="email" className="form-control" id="email" value="Email"/>
                                <div className="sl">                                
                                <select className="form-control" id="select_code" >
                                    <option value="+66">TH  +66</option> 
                                    <option value="+65">SG  +65</option> 
                                    <option value="+86">CN  +86</option>
                                    <option value="+852">HK  +852</option>
                                    <option value="+886">TW  +886</option>
                                    <option value="+1">USA  +1</option> 
                                    <option value="+44">UK  +44</option>                                  
                                </select>
                                <input type="tel" className="form-control" placeholder="Phone number" />
                                </div>
                            </div>

                            <div className="form-group d-flex">
                                <select className="form-control" id="select_Nationality">
                                <option>Nationality</option>
                                <option>Thai</option>
                                <option>Chinese</option>
                                </select>
                               
                            </div>

                            <h6 className="two">User Level</h6>
                            <div className="bt d-flex">
                                <div className="left">
                                <h6>level 1</h6>
                                <ul>
                                    <li>
                                    <p>Connect Wallet</p>
                                    <input
                                        type="checkbox"
                                        className="check-box__switcher"
                                        checked
                                    />
                                    </li>
                                    <li>
                                    <p>Registration</p>
                                    <input
                                        type="checkbox"
                                        className="check-box__switcher"
                                        disabled
                                    />
                                    </li>
                                    <li>
                                    <p>Secret Key</p>
                                    <input
                                        type="checkbox"
                                        className="check-box__switcher"
                                        disabled
                                    />
                                    </li>
                                   
                                                                     
                                </ul>
                                </div>
                                <div className="right">
                                <h6>level 2</h6>
                                <ul>
                                    <li>
                                    <p>Verify Email</p>
                                    <input
                                        type="checkbox"
                                        className="check-box__switcher"
                                        disabled
                                    />
                                    </li>     
                                    <li>
                                    <p>Buy ASC</p>
                                    <input
                                        type="checkbox"
                                        className="check-box__switcher"
                                        disabled
                                    />
                                    </li>                              
                                </ul>
                                </div>
                            </div>

                            <button type="submit" className="btn-action">
                                Update Profile
                            </button>
                            </form>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="content-inner referrals">
                            <h6>Total rewards</h6>
                            <h4>0.00 <span>ASC</span></h4>
                            <p>
                            You're Earning ASC Coin with invitation to friends by Your Referrals Code. 
                            <br/>Learn More
                            </p>
                            <div className="main">
                            <h6>Invite friends to earn Airdrop</h6>

                            <div className="refe">
                                <div>
                                <p>Referral link</p>
                                <input
                                    className="form-control"
                                    type="text"
                                    value="https://asc.land/register"
                                />
                                  <span className="btn-action">Copied</span>
                                </div>
                                <div>
                                <p>Referral code</p>
                                <input
                                    className="form-control"
                                    type="text"
                                    value="N84CRDKK"
                                />
                                <span className="btn-action">Copied</span>
                                </div>
                            </div>
                            </div>

                            <Link to="/wallet" className="btn-action">My Wallet</Link>
                        </div>
                    </TabPanel>                  
                   
                    <TabPanel>
                        <div className="content-inner profile change-pass">
                            <h4>Change Secret Key</h4>
                            <h6>Create Key 6-10 Numbers</h6>
                            <form action="#">

                            <div className="form-group">
                                <div>
                                <label>New Secret Key<span>*</span>:</label>
                                <input
                                    type="password"
                                    className="form-control"
                                />
                                </div>                               
                            </div>

                            <div className="form-group">
                                <div>
                                <label>Old Secret Key<span>*</span>:</label>
                                <input type="text" className="form-control"/>
                                </div>
                               
                            </div>

                            <div className="form-group">
                                <div>
                                <label>Phone Number<span>*</span>:</label>
                                <input
                                    type="password"
                                    className="form-control"
                                />
                                </div>                               
                            </div>

                            </form>
                            <button type="submit" className="btn-action">
                            Change Key
                            </button>
                        </div>
                    </TabPanel>
                    

                </Tabs> 
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default UserProfile;