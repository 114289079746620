import React , {useState, useEffect} from 'react';
import Cookies from 'js-cookie';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';



function Wallet(props) {   
    const [myWalletAddress, setWalletAddress] = useState("");
   
    
    useEffect( () => {
        const sessionWallet = Cookies.get('wallet');
        setWalletAddress(sessionWallet);              
     },[])

    const [dataCryptoTab] = useState([
        {
            id: 1,
            title: 'Crypto',
        },  
        {
            id: 2,
            title: 'Farms',
        },
        {
            id: 3,
            title: 'NFT',
        },           
    ])
    return (
        <div>
            <PageTitle heading='Wallet' title='Wallet' />
            <section className="wallet buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                    <Tabs>
                            <TabList>

                                {
                                    dataCryptoTab.map(idx => (
                                        <Tab key={idx.id}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>

                            <TabPanel>
                            <div className="content-inner">
                                <div className="wallet-main">
                                <h4 className="heading">My Wallet</h4>

                                <div className="wallet-body">
                                    <div className="left">
                                    <p>Total Balance</p>

                                    <div className="price">
                                        <h6>0.00</h6>
                                        <div className="sale warning">ASC</div>
                                    </div>
                                    <p>0 บาท</p>
                                    </div>
                                    <div className="right">                                   
                                        <div className="form-group">
                                        <input type="text" className="form-control" value={myWalletAddress}  disabled/>  

                                        <button type="button" className="btn-action btn-sm">
                                        Copy Address
                                        </button>

                                        </div>        
                                    </div>
                                </div>
                                </div>

                                <div className="coin-list-wallet">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th className="center" scope="col">Asset</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">24h %</th>
                                        <th scope="col">Total Balance</th>
                                        <th scope="col">Total Value</th>                                        
                                    </tr>
                                    </thead>
                                    <tbody>                                   
                          
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            </TabPanel>

                            <TabPanel>
                            <div className="content-inner">       
                                <div className="coin-list-wallet">
                                <table className="table">
                                    <thead>
                                    <tr>
                                                                <th scope="col"></th>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Funds</th>
                                                                <th scope="col">Total staked</th>
                                                                <th scope="col">APY %</th>
                                                                <th scope="col">Earned</th>
                                                                <th scope="col">Ends in</th>
                                                                <th scope="col">Status</th>                                                                
                                                                <th scope="col"></th>
                                                            </tr>
                                    </thead>
                                    <tbody>                                   
                          
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            </TabPanel>


                                      
  
                            

                    </Tabs> 

                    </div>
                </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default Wallet;