import React , {useState} from 'react';


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import { FaFileDownload } from "react-icons/fa";

import PDF from "../assets/pdf/WHITEPAPER_ASC_TOKEN.pdf";

import img2 from '../assets/images/layout/head-office.jpg'
import img3 from '../assets/images/layout/Picture8.png'
import img4 from '../assets/images/layout/Picture7.png'
import img_coin from '../assets/images/logo/Acoin-stack-512.png'
import img_logo from '../assets/images/logo/Acoin-512.png'
import img_ceo from '../assets/images/layout/ceo.png'
import { PieChart } from 'react-minimal-pie-chart';

function WhitePaper(props) {

    const [dataMenuTab] = useState([
        {
            id: 1,
            title: 'Introduction',
        },
        {
            id: 2,
            title: 'Backgroud',
        },
        {
            id: 3,
            title: 'Vision',
        },        
        {
            id: 4,
            title: 'Technology',
        },
        {
            id: 5,
            title: 'Token Economy',
        },
        {
            id: 6,
            title: 'Marketing',
        },
        {
            id: 7,
            title: 'Roadmap',
        },
        {
            id: 8,
            title: 'Team',
        },
    ])


    const dataChart = [
        { title: '8%', value: 8, color: '#F6942F' },
        { title: '29%', value: 15, color: '#F44E4E' },
        { title: '40%', value: 20, color: '#e8d8d8' },
        { title: '10%', value: 10, color: '#F3CE20' },
        { title: '3%', value: 3, color: '#5869CE' },
        { title: '5%', value: 5, color: '#22CB62' },
        { title: '5%', value: 5, color: '#57B8E0' },
      ];

      const defaultLabelStyle = {
        fontSize: '5px',
        fill: '#fdfdfd',
        fontFamily: 'Kanit',
      };



    return (
        <div>
            <PageTitle heading='White Paper' title='White Paper' />

            <section className="wallet sell buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                    <Tabs>
                            <TabList>

                                {
                                    dataMenuTab.map(idx => (
                                        <Tab key={idx.id}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>

                            <TabPanel>
                                <div className="content-inner">   
                                    <div className="wallet-main">
                                        
                                    <div className="wallet-body">
                                        <div className="left">
                                        <h6 className="heading">WhitePaper Version: 1.2</h6>
                                        <p>ASC Coin created by <br/> Amata Siam</p>
                                        </div>
                                        <div className="right">
                                        <div className="button">
                                            <a href={PDF} target = "_blank" rel="noreferrer" className="btn-sm btn-danger"><FaFileDownload /> PDF Download</a>
                                        </div>
                                        </div>
                                    </div>
                                    </div>

                                    <div className="main details">
                                    <h4 className="">Introduction</h4>
                                    <h6>การแนะนำตัว</h6>

                                    <center><img className="img-main mt-61" src={img_logo} alt="ASC"  width="50%"/></center>

                                    <p className='m-3'> 
                                        ASC Coin เป็น Utility Token 
                                        ที่พัฒนาโดยเทคโนโลยีมาตรฐาน BEP-20 บนเครือข่ายบล็อกเชน Binance Smart Chain กล่าวกันว่าเป็น Token ที่ใช้ในระบบนิเวศน์ของชุมชนพระเครื่องไทย
                                        โดยให้เกิดส่วนร่วมในการถือครองใช้งานหรือแลกเปลี่ยนกันภายในระบบของ ASC.Land
                                        </p>
                                   
                                    </div>
                                   
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="content-inner">   
                                    <div className="main details">
                                    <h4>Backgroud</h4>
                                    <h6>ความเป็นมาของบริษัทและองค์กร</h6>

                                    <img className="img-main mt-61" src={img2} alt="ASC"  width="90%"/>   
                                    
                                    <p className='m-3'> 
                                        บริษัท อมตะสยาม จำกัด เป็นผู้นำด้านนวัตกรรมในอุตสาหกรรมพระเครื่อง<br/>
                                        ด้วยเป็นเจ้าแรกของประเทศไทบที่มีเครื่องมือทางวิทยาศาสตร์
                                            ในการบ่งชี้อายุและความเก่าแก่ของเนื้อผงในตัวขององค์พระและยัง
                                            สามารถระบุเวลาที่ใกล้เคียงหรือแม่นยำกับยุคที่จัดสร้างขึ้นมาได้
                                            มากกว่า และด้วย CEO ที่มองเห็นถึงการนำเทคโนโลยีบล้อคเชน และ
                                            ได้นำไปสู่การคิดค้นพัฒนา ที่จะนำข้อมูลของระบบบล้อคเชน เข้ามา
                                            เก็บข้อมูลของพระเครื่องและนำเข้าสู่ระบบ NFT เพื่อทำให้คนทั่วไป
                                            สามารถรู้จักพระเครื่องและวัตถุโบราณมีค่าได้ง่ายกว่า แล้วยัง
                                            สามารถเข้าถือครองพระเครื่องที่มีมูลค่าหลายร้อยล้านบาทได้ด้วย
                                            ระบบ NFT ที่สามารถให้คนทั่วไปสามารถถือครองหุ้นในองค์พระนั้นๆ  ด้วยการนำ Token ASC ไปทำการซื้อหุ้นในระบบ NFT 
                                            ของ บริษัท อมตะสยาม จำกัด
                                                                
                                    </p> 
                                    <p className='m-3'> 
                                            โดยปกติการเช่าบูชาพระทั่วไปเราจะต้องนำไปเก็งกำไรด้วยตัวเอง
                                            หรือหาทอดตลาดอีกครั้ง แต่ที่ อมตะสยาม ได้มองเห็นช่วงทางในการ
                                            ทำธุรกิจพระเครื่องในโลกยุค 5G ที่สามารถเข้าถึงได้ง่านและไม่ต้องนำ
                                            พระไปทำการเก็งกำไร แต่ด้วยระบบ Block Chain ที่สามารถนำ
                                            เหรียญ ASC มาซื้อหุ้นขององค์พระแล้วนำไป Staking ด้วยระบบปัน
                                            ผลที่ทางอมตะสยามมอบให้กับสมาชิกที่เข้าร่วมโครงการ โดยเหรียญ
                                            ASC COIN ได้ถูกสร้างมาจำนวน 110,000,000 ASC ตามมูลค่าของ
                                            องค์พระที่เราใช้ค้ำประกันมูลค่าเหรียญ ทำให้นักลงทุนมีความสบายใจ
                                            ในการลงทุนและมั่นใจได้ว่า เหรียญจะไม่มีทางสูญเสียราคาที่จะต่ำกว่า
                                            มูลค่าของสินทรัพย์ค้ำประกัน เป็นที่รู้กันดีในบันดาวงการพระเครื่อง
                                            ว่ามูลค่าของชุดพระเบญจภาคี ทั้ง 5 องค์มีมูลค่าอย่างมากในตลาด
                                            พระเครื่อง                  
                                    </p>
                                    </div>                                    
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="content-inner">  
                                    <div className="main details">
                                    <h4 >Vision</h4>
                                    <h6>วิสัยทัศน์</h6>
                                    
                                    <center><img className="img-main mt-61" src={img_coin} alt="ASC coin"  width="50%"/></center> 

                                    <p className='m-3'> 
                                        ASC COIN เป็นโครงการที่ต้องการพัฒนาอุตสาหกรรมพระ
                                            เครื่องและวัตถุโบราณให้พัฒนาไปสู่โลกยุค 5G โดย CEO ได้มี
                                            วิสัยทัศน์มองเห็นความก้าวหน้าทางเทคโนโลยีบล็อคเชน แล้ว
                                            ต้องการนำมาประยุกต์ใช้กับอุปกรณ์ทางวิทยาศาสตร์ที่จะทำให้เรา
                                            สามารถระบุความเก่าแก่ของวัตถุโบราณและอายุของพระเครื่องได้
                                            โดยสามารถยังเก็บข้อมูลไว้ในระบบบล้อคเชนได้และยังมีระบบ
                                            Market Place ที่สามารถซื้อ-หรือสามารถเป็นเจ้าขององค์พระมูลค่า
                                            หลายล้านด้วยการถือหุ้นตามมูลค่านั้นๆ                                        
                                    </p>
                                    </div>                                    
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="content-inner">  
                                    <div className="main details">
                                    <h4>Technology</h4>
                                    <h6>เทคโนโลยีการตรวจสอบ</h6>
                                    <center><img className="img-main mt-61" src={img4} alt="ASC"  width="65%"/></center> 
                                    <p className='m-3'> 

                                    การตรวจสอบอายุโบราณวัตถุด้วยเทคนิคการเปล่งแสง (Luminescence
                                    dating)
                                    ด้วยเครื่อง TL/OSL Lexygresearch & Laser Ablation - Inductively
                                    Coupled Plasma Mass Spectrometry (LA-ICPMS)
                                    หลักการเปล่งแสงของแร่
                                    ผลึกแร่ที่สมบูรณ์จะมีการจัดเรียงตัวของประจุบวกและประจุลบอย่างสมดุล
                                    แต่ผลึกแร่ในธรรมชาติ ส่วนใหญ่มีความบกพร่อง ซึ่งเกิดได้จากหลายสาเหตุ
                                    และส่งผลบางตำแหน่งของการจัดเรียงตัวภายในผลึกแร่
                                    ไม่มีประจุลบและทำพฤติกรรมคล้ายหลุมกักเก็บอิเล็กตรอน (electrontrap)
                                    ซึ่งจะเป็นจุดดึงดูดให้อิเล็กตรอนที่กระจายโดยรอบเข้ามาฝังตัว
                                    พลังงานของหลุมกักเก็บนี้จะอยู่ในระดับพลังงานกระตุ้นกึ่งเสถียร
                                    เมื่อผลึกแร่ได้รับพลังงานจากสิ่งแวดล้อม เช่น
                                    พลังงานจากรังสีคอสมิกจากชั้นบรรยากาศหรือพลังงานจากการแผ่รังสีของ
                                    ธาตุกัมมันตรังสีที่มีในธรรมชาติ
                                    พลังงานเหล่านี้จะกระตุ้นให้อิเล็กตรอนโดยรอบหลุมกักเก็บเปลี่ยนระดับพลัง
                                    งานและเข้ามาฝังตัวในหลุมกักเก็บอิเล็กตรอน
                                    ซึ่งจำนวนของอิเล็กตรอนในหลุมกักเก็บนี้จะมีความสัมพันธ์กับปริมาณรังสีใน
                                    ธรรมชาติและระยะเวลาที่ผ่านมา
                                    ต่อมาเมื่อผลึกแร่ได้รับพลังงานแสงหรือความร้อนอีกครั้ง
                                    จะทำให้อิเล็กตรอนในหลุมกักเก็บถูกกระตุ้นให้หลุดจากหลุมกักเก็บและเปลี่ยน ระดับพลังงาน
                                    พลังงานส่วนต่างจะถูกปลดปล่อยออกมาในรูปของแสงหรือเกิดการเปล่งแสงนั่นเอง                    
                                    </p>

                                    <center><img className="img-main mt-61" src={img3} alt="ASC"  width="65%"/></center>

                                    <p  className='m-3'>Equivalent Dose สามารถทราบได้จากเครื่อง TL/OSL
                                    Lexygresearch หลักการทำงานของเครื่องดังกล่าว คือ
                                    การให้แสงหรือความร้อนกระตุ้นไปยังตัวอย่าง
                                    เมื่ออิเล็กตรอนที่อยู่ในหลุมกักเก็บภายในผลึกของตัวอย่างถูกกระตุ้นก็จะคาย
                                    พลังงานออกมาในรูปของการเปล่งแสง
                                    ตัววัดสัญญาณภายในเครื่องมือจะวัดปริมาณการเปล่งแสงเทียบกับเวลา
                                    ซึ่งปริมาณการเปล่งแสงนี้จะสัมพันธ์กับจำนวนอิเล็กตรอนที่มีในหลุมกักเก็บแ
                                    ละจะมีค่าลดลงเรื่อย ๆ ตามเวลาที่ถูกกระตุ้น
                                    Annual Dose (AD)
                                    คืออัตราการแผ่รังสีต่อปีของธาตุกัมมันตรังสีในธรรมชาติและรังสีคอสมิก
                                    มีหน่วยเป็นเกรย์ต่อปี (Gy/year)
                                    Annual Dose สามารถทราบได้จากเครื่อง Laser Ablation - Inductively
                                    Coupled Plasma Mass Spectrometry (LA-ICPMS)
                                    โดยการใช้พลังงานจากแสงเลเซอร์เพื่อให้ตัวอย่างระเหิดและแตกตัวเป็นไอออ
                                    น แล้ววัดกระแสไอออน
                                    ซึ่งเป็นสัดส่วนโดยตรงกับความเข้มข้นของธาตุกัมมันตรังสีในธรรมชาติที่มีผ
                                    ลต่อการกระตุ้นอิเล็กตรอนให้เข้าไปฝังตัวในหลุมกักเก็บ
                                    โดยธาตุกัมมันตรังสีดังกล่าว ได้แก่ ธาตุยูเรเนียม (U) ธาตุทอเรียม (Th)
                                    และธาตุโพแทสเซียม (K)
                                    ธาตุเหล่านี้จะสลายตัวไปตามค่าครึ่งชีวิตและทำให้เกิดอนุภาคแอลฟา
                                    อนุภาคบีตา และรังสีแกมมา
                                    ซึ่งอัตราการแผ่รังสีต่อปีนี้จะมีค่าขึ้นกับปริมาณของอนุภาคเหล่านี้
                                    และปริมาณของอนุภาคเหล่านี้ก็ขึ้นอยู่กับความเข้มข้นของธาตุกัมมันตรังสี U
                                    Th และ K</p>
                                    </div>                                    
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="content-inner">     

                                    <div className="main details">
                                    <h4 className="heading">Token Economy</h4>
                                    <div>
                                    <PieChart
                                        data={dataChart}
                                        label={({ dataEntry }) => dataEntry.title}
                                        labelStyle={{...defaultLabelStyle,}}
                                        radius={40}
                                        style={{ height: '450px' }}
                                        />   
                                    </div>
                                    <div className="coin-list-wallet"> 
                                   <center>
                                    <table className="table" style={{width: '450px', color: '#b8b8b8'}}>
                                      
                                            <tr className="center">
                                            <th>Market Place</th>
                                            <th>40%</th>
                                            <th>44,000,000 ASC</th>
                                            </tr>
                                            <tr className="center">
                                            <th>Staking Rewards</th>
                                            <th>29%</th>
                                            <th>31,900,000 ASC</th>
                                            </tr>
                                            <tr className="center">
                                            <th>Ecosystem Fund</th>
                                            <th>8%</th>
                                            <th>8,800,000 ASC</th>
                                            </tr>
                                            <tr className="center">
                                            <th>Private Sale</th>
                                            <th>5%</th>
                                            <th>5,500,000 ASC</th>
                                            </tr>
                                            <tr className="center">
                                            <th>Public Sale</th>
                                            <th>5%</th>
                                            <th>5,500,000 ASC</th>
                                            </tr>
                                            <tr className="center">
                                            <th>Air Drop</th>
                                            <th>3%</th>
                                            <th>3,300,000 ASC</th>
                                            </tr>
                                            <tr className="center">
                                            <th>Advisor</th>
                                            <th>10%</th>
                                            <th>11,000,000 ASC</th>
                                            </tr>
                                            
                                    </table>  
                                    </center>
                                    </div>                                                                 
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="content-inner">     

                                    <div className="main detailst">
                                    <h4 className="heading">Marketing</h4>
                                    <h6>แผนธุรกิจในอนาคต</h6>
                                    <br/>
                                    <h6 className='mt-5'>-- อมตะ สยาม</h6>
                                    <p className='m-5'> 
                                        • ปัจจุบันให้บริการ
                                        ตรวจสอบและวิจัยอายุโบราณวัตถุวัตถุโบราณต่างๆ รวมถึงการตรวจหาค่าอายุการสร้างของพระเครื่องด้วยเครื่องมือทางวิทยาศาสตร์ รับรองโดยนักวิทยาศาสตร์ที่มีความเชี่ยวชาญและชำนาญการโดยเฉพาะ 
                                        ซึ่งถือได้ว่าเป็น สถาบันเอกชนสถาบันแรก ที่สามารถกำหนดอายุของวัตถุโบราญได้โดยเป็นสถานที่ ที่มีเครื่องมือที่ครบถ้วน และเจ้าหน้าที่พร้อมทำงานตรงสาย 
                                        ตลาดกลางการซื้อขายพระเครื่อง เครื่องราง และของล้ำค้าทุกชนิด
                                        เป็นตลาดที่เปิดโอกาศให้ นักสะสม นักลงทุน นักขาย หลากหลายอาชีพที่สามารถเข้ามาเป็นสมาชิก เป็นพาร์ทเนอร์ ในการเปิดร้านบนแพทฟรอม เพื่อซื้อหรือขายสินค้าที่ต้องการ                                    
                                    </p>

                                    <h6 className='mt-5'>-- จัดตั้งสถาบันตรวจสอบเพิ่มสาขา </h6>
                                    <p className='m-5'> 
                                        • เริ่มที่ปรเทศไทย 6 ภาค
                                                ภาคเหนือ
                                                ภาคตะวันออกเฉียงเหนือ
                                                ภาคตะวันตก
                                                ภาคกลาง
                                                ภาคตะวันออก
                                                ภาคใต้
                                                ตามลำดับ เพื่ออำนวยความสะดวกและ ขยายตลาดการตรวจสอบโบราณวัตถุ ให้สอดคล้องกับความต้องการของตลาด นักสะสมของเก่า นักสะสมพระเครื่อง และอื่นๆที่เกี่ยวข้องกับการหาอายุ
                                                    และในภายภาคหน้า อมตะ สยาม 
                                                    มีโครงการตรวจสอบอัญมณี เพชร พลอย และเปอร์เซ็นของ วัตถุธาตุที่มีราคาทุกชนิด
                                                การตรวจสารปนเปื่อนในอาหาร หรือในผลิตภันอาหารเสริมต่างๆ เพื่อใช้ประสิทธิภาพของเครื่องมือให้ได้ประสิทธิภาพสูงที่สุด                                  
                                    </p>

                                    <h6 className='mt-5'>--	แผนการขยายตลาดกลาง  </h6>
                                    <p className='m-5'> 
                                        • เปิดโอกาสให้นักลงทุนทั่วโลกที่สนใจในเรื่องของเก่าของโบราณ รวมถึงพระเครื่องด้สามารถลงทุนในการซื้อขายผ่าน ตลาดกลางของอมตะ สยาม ไม่ว่าจะเป็นการสะสมหรือการซื้อเพื่อเกงกำไร สามารถซื้อขายปลอดภัยด้วยระบบยืนยันด้วย บล็อคเช็นและยืนยันความเป็นเจ้าจองโดยใช้ NFT เพื่อแสดงสิทธิ พร้องได้สินค้าจริงที่สามารถจับต้องและถือครองได้                                  
                                    </p>

                                    <h6 className='mt-5'>--	ASC Coin  </h6>
                                    <p className='m-5'> 
                                        • นำเหรียญ ASC coin เข้าเทรดในตลาดคลิปโตเพื่อเพิ่มมูลค่าและสภาพคล่องของเหรียญด้วยเช่นกัน                                  
                                    </p>

                                    </div>                                    
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="content-inner">     

                                    <div className="coin-list-wallet">
                                    <h4 className="heading">Road Map</h4>

                                    <h6>-- 2022</h6>
                                    <p className='m-5'> 
                                        • วางแผนพัฒนาระบบ Technology Blockchain มาประยุกต์ใช้กับระบบ Technology การตรวจหาความเก่าของวัตถุและพระเครื่องในประเทศไทย                                        
                                    </p>
                                    <h6>-- 2023</h6>
                                    <p className='m-5'>                                       
                                        • สรุปแผนงานและจัดเตรียมวางระบบกระบวนการ ASC COIN
                                    </p>
                                    
                                    <h6>-- 2024</h6>
                                    <p className='m-5'>
                                        Q1<br/>
                                        • สร้าง ASC COIN Total Supply 110,000,000 Token<br/>
                                        • White Paper List ASC Coin<br/>
                                        • Create Website (www.asc.land)
                                    </p> 
                                    <p className='m-5'>
                                        Q2<br/>
                                        • ICO ASC Coin 1,000,000 Token<br/>
                                        • Air drop ASC Coin 100,000 Token<br/>
                                        • Staking ASC Coin<br/>
                                        • Community Start 1000 User On Telegram
                                    </p> 
                                    <p className='m-5'>
                                        Q3<br/>
                                        • Market Place<br/>
                                        • NFT<br/>                                       
                                    </p> 
                                    <p className='m-5'>
                                        Q4<br/> 
                                        • Liquidity Pool                                                                          
                                    </p> 

                                    <h6>-- 2025</h6>
                                    <p className='m-5'>                                         
                                        Q3<br/> 
                                        • Exchange Coin Market Cap<br/> 
                                        • Exchange Pool coin<br/> 
                                        • Exchange Pancake Swap
                                    </p>
                                    </div>                                 
                                </div>
                            </TabPanel>  


                            
                            <TabPanel>
                                <div className="content-inner">     

                                    <div className="main detailst">
                                    <h4 className="heading">Team</h4>
                                    <h6>ทีมงานผู้สร้าง</h6>  
                                            <center><img className="img-main mt-61" src={img_ceo} alt="ASC coin"  width="320"/></center> 
                                            <h6 className='m-3 center'>CEO</h6>
                                    <p className='m-3'> 
                                    ชื่อ : ดร.ธนภชพร ศิริเพชร<br/><br/>
ประวัติการศึกษา 	: 2005 รัฐประศาสนศาสตร์บัณฑิต (การปกครองท้องถิ่น) มหาวิทยาลัยบัณฑิตบริหารธุรกิจขอนแก่น<br/>
2020 รัฐประศาสนศาสตร์มหาบัณฑิต คณะรัฐศาสตร์ มหาวิทยาลัยปทุมธานี<br/>
2024 รัฐประศาสนศาสตร์ดุษฏีบัณฑิต คณะรัฐศาสตร์ มหาวิทยาลัยปทุมธานี<br/><br/>

ประวัติการทำงาน	:  ผู้ก่อตั้งศูนย์พระเครื่องบารมีสมเด็จโต (อมตะ สยาม)  ให้บริการตรวจสอบพระเครื่องพระสมเด็จวัดระฆัง โฆสิตาราม วัดบางขุนพรหม และวัดเกษไชโยด้วย หลักวิทยาศาสตร์อิงธรรมชาติวิทยาที่โด่งดังของ ประเทศไทย<br/>

ผู้ก่อตั้งและประธานกรรมการบริหาร บริษัท อมตะ สยาม (2017) จำกัด และ บริษัท อมตะ สยาม คอร์ปอเรชั่น จำกัด<br/>

ผู้ก่อตั้งสถาบันตรวจสอบและวิจัยวัตถุ โบราณ (อมตะ สยาม) Amata Siam Science center ให้บริการ ตรวจอายุพระเครื่องและโบราณวัตถุด้วยเทคนิคการ เปล่งแสง (Luminescence Dating)<br/>

ผู้เชี่ยวชาญและวิทยากรให้ความรู้เรื่องพระเครื่อง พระสมเด็จวัดระฆังโฆสิตาราม วัดบางขุนพรหม และวัดเกษไชโย พระชุดเบญจภาคี เบญจภาคีเหรียญ เบญจภาคี พระปิดตา เบญจภาคีเนื้อชิน และเป็นผู้เชี่ยวชาญเรื่องธาตุกายสิทธิ<br/>

                                                                
                                    </p> 

                                    </div> 
                                    </div>  
                            </TabPanel>  
                    </Tabs> 

                    </div>
                </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default WhitePaper;