

import img1 from '../images/partner/logo-01.png';
import img2 from '../images/partner/logo-02.png';
import img3 from '../images/partner/logo-03.png';
import img4 from '../images/partner/logo-04.png';


const dataPartner = [
    {
        id: 1,
        img: img1,
    },
    {
        id: 2,
        img: img2,
    },
    {
        id: 3,
        img: img3,
    },
    {
        id: 4,
        img: img4,
    },
    {
        id: 5,
        img: img1,
    },
    {
        id: 6,
        img: img2,
    },
   
]

export default dataPartner;