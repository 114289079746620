import React from 'react';
import {Button} from '../components/button';
import img from '../assets/images/layout/error404.png'

function Page404(props) {
    return (
        <div>       

        <section className="contact">
        <div className="container">
            <div className="row">
            <div className="col-xl-12 col-md-12">   
                <div className="contact-main">
                <div className="block-text center">
                    <div className="image">
                        <img src={img} class="center" alt="" width={580} />
                    </div>                    
                    <p className="desc fs-20">หน้าเว็บที่คุณกำลังพยายามเข้าถึงไม่สามารถใช้งานได้</p>
                    <div className="mt-25">
                        <Button title='Back to Home' path='/'/>
                    </div>
                </div>  
                </div>
            </div>
            </div>
        </div>
        </section>
        </div>
    );
}

export default Page404;