import React , {useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';




function Coinlist01() {

    const [coinData, setCoinData] = useState([]);

    const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum%2Cbinancecoin%2Cripple%2Ctether%2Ctron&order=market_cap_desc';
    const options = {
      method: 'GET',
      accept: 'application/json',
      headers: {'x-cg-demo-api-key': 'CG-aRSKtaHhr4mATiwp92wR4Ad8'}
    };    


    useEffect(() => {
        async function fetchData() {
          
          await axios.get(url,options)
          .then(response => {setCoinData(response.data)})
          .catch(error => {console.log(error)});
          
        }
        fetchData();
      }, []);            

    const [dataCoinTab] = useState([
        {
            id: 1,
            title: 'View All',
        },
        {
            id: 2,
            title: 'BSC Chain',
        },
        {
            id: 3,
            title: 'ETH Chain',
        },      
    ]);

    
    return (
        <section className="coin-list">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text">
                    <h3 className="heading">Coin Market</h3>
                    <Link to="https://www.coingecko.com/" className="btn-action-2 small" >Powered by CoinGecko</Link>
                    </div>

                    <div className="coin-list__main">
                    <Tabs>
                            <TabList>
                                {
                                    dataCoinTab.map(idx => (
                                        <Tab key={idx.id}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>

                            {
                                dataCoinTab.map(data => (
                                    <TabPanel key={data.id}>
                                        <div className="content-inner">
                                        <table className="table">
                                            <thead>
                                                <tr>                                                   
                                                    <th scope="col">#</th>
                                                    <th scope="col">Coin</th>
                                                    <th scope="col">Last_Prices</th>
                                                    <th scope="col">24h_%</th>
                                                    <th scope="col">24h_Highest</th>
                                                    <th scope="col">24h_Lowest</th>
                                                    <th scope="col">Market_Cap</th>                                                                                                     
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    coinData.map((data,i) => {
                                                        return (
                                                        <tr key={i}>                                                            
                                                            <td>{i+1}</td>
                                                            <td>
                                                            <Link to="#">
                                                                <img src={data.image} alt="icon"  width={30}/>
                                                                <span>{data.name}</span>
                                                                <span className="unit">{data.symbol}</span>
                                                            </Link>
                                                            </td>
                                                            <td className="boild">${data.current_price.toLocaleString()}</td>
                                                            <td className={data.price_change_percentage_24h < 0 ? 'down' :'up'}>{data.price_change_percentage_24h.toLocaleString()}%</td>
                                                            <td className="">${data.high_24h.toLocaleString()}</td>
                                                            <td className="text-muted">${data.low_24h.toLocaleString()}</td>
                                                            <td className="boild">${data.market_cap.toLocaleString()}</td>                                                                                                                    
                                                        </tr>
                                                        
                                                    )}
                                                )}
                                                
                                            </tbody>
                                            </table>                                            
                                                   
                                        </div>
                                    </TabPanel>
                                ))
                            }
                            

                        </Tabs> 
                    </div>
                </div>
                </div>
            </div>
            </section>
    );
}

export default Coinlist01;