import React , { useState ,useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

import imgCoin from '../../assets/images/logo/Acoin-mini.png'

import { RiDiscordLine, RiTwitterXLine, RiYoutubeLine } from "react-icons/ri"
import { FaTelegramPlane, FaFacebookF } from "react-icons/fa";


function Footer(props) {

    const [productLink] = useState([
        {
            title: 'ASC Coin',
            path: '/'
        },
        {
            title: 'Asset Token',
            path: '#'
        },
        {
            title: 'Staking',
            path: '#'
        }, 
        {
            title: 'NFT',
            path: '#'
        },        
        {
            title: 'White Paper',
            path: '#'
        },
    ]);
    const [servicesLink] = useState([      
        {
            title: 'Amata Markets',
            path: 'https://www.amatamarket.com/'
        },
        {
            title: 'Buy Coin',
            path: '/buy-coin'
        },
        {
            title: 'User Profile',
            path: '/user-profile'
        },
        {
            title: 'My Wallet',
            path: '/wallet'
        },
        {
            title: 'API',
            path: '#'
        },
    ]);
    

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (

        <footer className="footer style-2">
            <div className="container">
                <div className="footer__main">
                <div className="row">
                    <div className="col-xl-4 col-md-6">                
                        <div className="content-inner my-5">                               
                                <div className="main">                               
                                    <img src={imgCoin} alt="asc coin" width="32" />
                                    <span> ASC Coin</span>                               
                                 
                                <ul className="list mt-3">                                   
                                    <li><p className='small'>Price : ฿10</p></li>                                      
                                    <li><p className='small'>Market Cap : $0</p></li>                                        
                                    <li><p className='small'>Total Supply : 110,000,000</p></li>   
                                </ul>                              

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                    <div className="widget">
                        <div className="widget-link">
                        <h6 className="title">PRODUCTS</h6>
                        <ul>
                            {
                                productLink.map((data,idx) => (
                                    <li key={idx}><Link to={data.path}>{data.title}</Link></li>
                                ))
                            }
                        </ul>
                        </div>
                        <div className="widget-link s2">
                        <h6 className="title">SERVICES</h6>
                        <ul>
                            {
                                servicesLink.map((data,idx) => (
                                    <li key={idx}><Link to={data.path}>{data.title}</Link></li>
                                ))
                            }
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-4 col-md-12">
                    <div className="footer-contact">
                        <h5>Community</h5>
                        <p>Follow me to get more News and Event.</p>
                       
                        <ul className="list-social">
                        <li><Link to="#"><span><FaFacebookF /></span></Link></li>
                        <li><Link to="https://discord.gg/fv8fZZcgUC"><span><RiDiscordLine /></span></Link></li>
                        <li><Link to="https://t.me/asccointh"><span><FaTelegramPlane /></span></Link></li>
                        <li><Link to="https://twitter.com/TokenAsc72364"><span><RiTwitterXLine /></span></Link></li>
                        <li><Link to="https://www.youtube.com/@user-wu4td8md2z"><span><RiYoutubeLine /></span></Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="footer__bottom">
                <p>
                    ©2024 asc.land All rights reserved. Terms of Service | Privacy
                    Terms
                </p>
                </div>
            </div>

            {
                isVisible && 
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
        </footer>
    );
}

export default Footer;