

import img2 from '../images/icon/Cloud.png';
import img1 from '../images/icon/Wallet.png';
import img4 from '../images/icon/Mining.png';
import img3 from '../images/icon/Comparison.png';

const dataWork = [
    {
        id: 1,
        img: img1,
        step: 'Step 1',
        title: 'Connect wallet',
        text: 'เชื่อมต่อกระเป๋า MetaMask เข้ากับระบบเว็บไซต์'
    },
    {
        id: 2,
        img: img2,
        step: 'Step 2',
        title: 'Register',
        text: 'สมัครลงทะเบียนข้อมูลส่วนบุคคลเบื้องต้น'
    },
    {
        id: 3,
        img: img3,
        step: 'Step 3',
        title: 'Buy ASC',
        text: 'ซื้อเหรียญ ASC เพื่อใช้ในการทำธุรกรรมและการลงทุน'
    },
    {
        id: 4,
        img: img4,
        step: 'Step 4',
        title: 'Staking',
        text: 'นำเหรียญ ASC ที่คุณมีมาฝากเพื่อรับผลตอบแทนพิเศษ'
    },
   
]

export default dataWork;