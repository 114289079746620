import React from 'react';
import Sale01 from '../components/sale/Sale01';

import img from '../assets/images/layout/contact.png'
import img_Telegram from '../assets/images/layout/qr-telegram.png'
import PageTitle from '../components/pagetitle';


function Contact(props) {
    return (
        <div>
            <PageTitle heading='Contact' title='Contact' />

            <section className="contact">
            <div className="container">
                <div className="row">
                <div className="col-xl-12 col-md-12">   
                    <div className="contact-main">
                    <div className="block-text center">
                        <div className="image">
                            <img src={img} class="center" alt="" width={650} />
                        </div>
                        
                        <h3 className="heading">Follow And Contact us</h3>
                        <p className="desc fs-20">Get News and Event With Community ASC</p>
                    </div>

                    <div className="block-text center mt-15">
                        <div className="image">
                            <img src={img_Telegram} class="center" alt=""  width={256}/>
                         </div>
                    </div>

                    </div>
                </div>
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default Contact;