const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',    
    },    
    {
        id: 2,
        name: 'White Paper',
        links: '/white-paper',       
    },
    {
        id: 3,
        name: 'Buy',
        links: '#',
        namesub: [           
            {
                id: 1,
                sub: 'ASC Coin',
                links: '/buy-coin',
            },
            {
                id: 2,
                sub: 'Asset Token',
                links: '/asset-token', 
                
            },          

        ]
    },    
    {
        id: 4,
        name: 'Staking',
        links: '/staking'
    }, 
    {
        id: 6,
        name: 'Marketplace',
        links: 'https://www.amatamarket.com/',         
        
    },
    {
        id: 5,
        name: 'Amulets',
        links: '/amulets'
    },   
    {
        id: 7,
        name: 'NFT',
        links: 'https://nftmarket.asc.land/',
    },  
    {
        id: 8,
        name: 'Contact',
        links: '/contact'
    },

    
]

export default menus;