import React , {useState} from 'react';
import PropTypes from 'prop-types';
import img_cert1 from '../../assets/images/layout/cert01.jpg';
import img_amulet from '../../assets/images/layout/Picture1.jpg';

Services02.propTypes = {
    data: PropTypes.array
};

function Services02(props) {
    const {data} = props;

    const [dataBlock] = useState(
        {
            heading: 'Why choose Me ?',
            desc : ''
        }
    )
    
    return (
        <section className="services-2 bg">
        <div className="container">
            <div className="row">
            <div className="col-xl-6 col-md-12">
                <div
                className="services__content"
                data-aos="fade-up"
                data-aos-duration="1000"
                >
                <h3 className="heading">{dataBlock.heading}</h3>
                <p className="fs-20 desc">
                {dataBlock.desc}
                </p>
                <ul className="list">
                    {
                        data.map(idx =>(
                        <li key={idx.id} className={idx.active}>
                            <div className={`icon ${idx.bg}`}>
                                <img src={idx.icon} alt="ASC" />
                            </div>
                            <div className="content">
                                <h6 className="title">{idx.title}</h6>
                                <p>
                                    {idx.text}
                                </p>
                            </div>
                        </li>
                        ))
                    }
                    
                </ul>
                </div>
            </div>
            <div className="col-xl-6 col-md-12">
                <div className="services_image">
                <div className="experience">
                    <h6 className="fs-18">Amulet of Thailand</h6>
                   <img src={img_amulet} alt='asc amulet' width="80%"/>
                </div>
              
                <div className="shape"></div>            
                </div>
            </div>
            </div>
        </div>
        </section>
    );
}

export default Services02;