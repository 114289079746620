

import img1 from '../images/icon/done.png'
import img2 from '../images/icon/manager.png'
import img3 from '../images/icon/users.png'
const dataServices2 = [
    {
        id: 1,
        icon: img1,
        title: 'Secure',
        text: 'บริการปลอดภัย มีมาตรฐานการตรวจสอบ',
        active: 'active',
        bg:'green'
    },
    {
        id: 2,
        icon: img2,
        title: 'Manage',
        text: 'เราคือผู้บริหารจัดการลงทุนในพระเครื่องที่มีประสิทธิภาพ',
        active: 'active',
        bg:''
    },
    {
        id: 3,
        icon: img3,
        title: 'community',
        text: 'เรามีชุมชนแห่งการแบ่งปันและสร้างโอกาสให้กับผู้คน',
        active: 'active',
        bg:'blue'
    },
   
]

export default dataServices2;