import React , {useEffect, useState} from 'react';
import Cookies from 'js-cookie';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import ImgNFT01 from '../assets/images/nft/Picture1.jpg'
import ImgNFT02 from '../assets/images/nft/Picture2.jpg'
import ImgNFT03 from '../assets/images/nft/Picture3.jpg'
import ImgNFT04 from '../assets/images/nft/Picture4.jpg'


function NFTmarket(props) {

    const [thbValue, setThbValue] = useState(0);
    const [ascValue, setAscValue] = useState(0);
    
    const sessionWallet = Cookies.get('wallet');
    const [myWalletAddress, setWalletAddress] = useState("");
   
    
    useEffect( () => {
        setWalletAddress(sessionWallet);              
     },[])

    useEffect( () => {      
        setThbValue(ascValue*100);

    },[ascValue])



   return (
        <div>
            <PageTitle heading='NFT Market' title='NFT' />

            <section className="buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                 
                            <div className="content-inner">
                                <div className="top">
                                <h3 className='center'>Coming soon..</h3>
                                </div>

                                <div className="main">
                                <div className='row'>

                                <div className='col-md-3 col-6'>
                                <div className="card">
                                    <img className="card-img-top" src={ImgNFT01} alt="Card cap"/>
                                    <div className="card-body">                                      
                                        <p className="card-text">Card Name</p>
                                       
                                    </div>
                                    </div>
                                </div>

                                <div className='col-md-3 col-6'>
                                <div className="card" >
                                    <img className="card-img-top" src={ImgNFT02} alt="Card cap"/>
                                    <div className="card-body">                                       
                                        <p className="card-text">Card Name</p>
                                       
                                    </div>
                                </div>
                                </div>

                                <div className='col-md-3 col-6'>
                                <div className="card" >
                                    <img className="card-img-top" src={ImgNFT03} alt="Card cap"/>
                                    <div className="card-body">                                      
                                        <p className="card-text">Card Name</p>
                                       
                                    </div>
                                </div>
                                </div>

                                <div className='col-md-3 col-6'>
                                <div className="card" >
                                    <img className="card-img-top" src={ImgNFT04} alt="Card cap"/>
                                    <div className="card-body">                                      
                                        <p className="card-text">Card Name</p>
                                       
                                    </div>
                                </div>
                                </div>

                                </div>
                                </div>
                            </div>
                           

                    </div>
                </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default NFTmarket;