import React , {useState,useEffect} from 'react';

import {Link} from 'react-router-dom';

function Sale01(props) {

    const [dataBlock] = useState(
        {
            title: 'Stake ASC to Earn up to 10-25% APY',
            desc : 'สินทรัพย์ดิจิทัลที่มีเอกลักษณ์เฉพาะตัว — และยังสามารถรับผลตอบแทนที่มั่นคงได้ด้วยตัวคุณเอง',
        }
    )

    const [myWalletAddress, setWalletAddress] = useState("");
   
    useEffect( () => {
        metaConnected();
        changeWallet();

    },[])

    
    const changeWallet = async() =>{
        if(typeof window !== "undefined" && window.ethereum !== "undefined"){        
            window.ethereum.on("accountsChanged",(accounts) =>{setWalletAddress(accounts[0]);});    
          }
      }

      const metaConnected = async() =>{
        if(typeof window !== "undefined" && window.ethereum !== "undefined"){
            try{
              const accounts = await window.ethereum.request({method: "eth_accounts"});
              if(accounts.length > 0){
                setWalletAddress(accounts[0]);
              }
                
            }catch(err){
              console.error(err.message)
            }
        }
    }


    return (
        <section className="section-sale">
            <div className="container">
            
                <div className="row align-item-center">
                <div className="col-md-7">
                    <div className="block-text">
                    <h4 className="heading">{dataBlock.title}</h4>
                    <p className="desc">
                        {dataBlock.desc}
                    </p>
                    </div>
                </div>
                <div className="col-md-5">
                    {
                    myWalletAddress && myWalletAddress.length > 0  ? 
                    <div className="button"><Link to="/staking">Staking Now</Link></div>
                    : '' }
                </div>
                </div>
            </div>
            </section>
    );
}

export default Sale01;