import React , {useState} from 'react';

import PageTitle from '../components/pagetitle';
import 'react-tabs/style/react-tabs.css';

import icon_cn03 from '../assets/images/icon/icon-mining02.png'



function Staking(props) {


    return (
        <div className='markets home-2'>       

           <PageTitle heading='Staking' title='Staking' />

            <section className="coin-list">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12">

                   

                        <div className="flat-tabs1">
                        <ul className="menu-tab1">                            
                            <li className="active"><h6 className="fs-16">ASC Private Staking</h6></li>  
                                            
                        </ul>
                        <div className="content-tab1">
                            <div className="content-inner">
                            <div className="flat-tabs2">                               
                                <div className="content-tab2">
                                <div className="content-inner">
                                    <div className="coin-list__main">

                                    <div className='row'>
                                        <div className='col-md-4 col-xs-12 p-3'>
                                        <div className="card rounded-3">
                                            <div className="center">
                                            <img className="m-2" src={icon_cn03} alt="Card defi" width={128}/>
                                            <p className="card-text text-black">ASC Stake V01</p>
                                            <h5 className='text-black'>3x</h5>
                                            </div>                                           
                                            <hr/>
                                            <div className="card-body">                                                
                                                <table class="table table-success">
                                                <tbody>
                                                    <tr>
                                                        <th className='text-muted'>Avg. APY :</th>
                                                        <th className=''>3%</th>
                                                    </tr>                                                   
                                                    <tr>
                                                        <th className='text-muted'>Ends in :</th>
                                                        <th className=''>180 day</th>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-muted'>Total staked :</th>
                                                        <th className=''>0.00</th>
                                                    </tr>
                                                </tbody>
                                                </table>   
                                                <p className="text-sm">Earned rewards</p>
                                                <p className="text-sm">0.00 ASC</p>    
                                                <hr/>
                                                <div className="center">  
                                                    <div class="d-grid gap-2">
                                                    <a className='btn btn-primary m-3'> Stake</a>
                                                    </div>                                                   
                                                <p className="text-sm">Status: <span className="text-danger">Closed</span></p>
                                                </div>                                              
                                               
                                            </div>
                                            </div>
                                        </div>

                                        <div className='col-md-4 col-xs-12 p-3'>
                                        <div className="card" >                                            
                                            <div className="center">
                                                <img className="m-2" src={icon_cn03} alt="Card defi" width={128}/>
                                                <p className="card-text text-black">ASC Stake V02</p>
                                                <h5 className='text-black'>10x</h5>
                                            </div>                                           
                                            <hr/>
                                            <div className="card-body">                                                
                                                <table class="table table-warning">
                                                <tbody>
                                                    <tr>
                                                        <th className='text-muted'>Avg. APY :</th>
                                                        <th className=''>10%</th>
                                                    </tr>                                                    
                                                    <tr>
                                                        <th className='text-muted'>Ends in :</th>
                                                        <th className=''>365 day</th>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-muted'>Total staked :</th>
                                                        <th className=''>0.00</th>
                                                    </tr>
                                                </tbody>
                                                </table>   
                                                <p className="text-sm">Earned rewards</p>
                                                <p className="text-sm">0.00 ASC</p>    
                                                <hr/>
                                                <div className="center">  
                                                    <div class="d-grid gap-2">
                                                    <a className='btn btn-primary m-3'> Stake</a>
                                                    </div>                                                   
                                                <p className="text-sm">Status: <span className="text-danger">Closed</span></p>
                                                </div>                                              
                                               
                                            </div>
                                        </div>
                                        </div>

                                        <div className='col-md-4 col-xs-12 p-3'>
                                        <div className="card" >
                                        <div className="center">
                                            <img className="m-2" src={icon_cn03} alt="Card defi" width={128}/>
                                            <p className="card-text text-black">ASC Stake V03</p>
                                            <h5 className='text-black'>12.5x</h5>
                                            </div>                                           
                                            <hr/>
                                            <div className="card-body">                                                
                                                <table class="table table-info">
                                                <tbody>
                                                    <tr>
                                                        <th className='text-muted'>Avg. APY :</th>
                                                        <th className=''>12.5%</th>
                                                    </tr>                                                    
                                                    <tr>
                                                        <th className='text-muted'>Ends in :</th>
                                                        <th className=''>730 day</th>
                                                    </tr>
                                                    <tr>
                                                        <th className='text-muted'>Total staked :</th>
                                                        <th className=''>0.00</th>
                                                    </tr>
                                                </tbody>
                                                </table>   
                                                <p className="text-sm">Earned rewards</p>
                                                <p className="text-sm">0.00 ASC</p>    
                                                <hr/>
                                                <div className="center">  
                                                    <div class="d-grid gap-2">
                                                    <a className='btn btn-primary m-3'> Stake</a>
                                                    </div>                                                   
                                                <p className="text-sm">Status: <span className="text-danger">Closed</span></p>
                                                </div>                                              
                                               
                                            </div>

                                        </div>
                                        </div>

                                      
                                        </div>


                                    </div>
                                </div>

                                </div>
                            </div>
                            </div>

                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default Staking;