import React from 'react';

import Banner01 from '../components/banner/Banner01';
import Crypto01 from '../components/crypto/Crypto01';
import Coinlist01 from '../components/coinlist/Coinlist01';
import Work01 from '../components/work/Work01';
import About01 from '../components/about/About01';

import Services02 from '../components/services/Services02';
import Counter from '../components/counter/Counter';
import Testimonial02 from '../components/testimonial/Testimonial02';


import Sale01 from '../components/sale/Sale01';
import dataPartner from '../assets/fake-data/data-partner';
import dataServices2 from '../assets/fake-data/data-services-2';
import dataTestimonial2 from '../assets/fake-data/data-testimonial2';
import dataWork from '../assets/fake-data/data-work';


function HomeOne(props) {
    

    return (
        <div className='home-1'>

            <Banner01 data={dataPartner} />

            <Crypto01 />

            <Coinlist01 />

            <Work01 data={dataWork} />

            <About01 />   

            <Services02 data={dataServices2} />

            <Testimonial02 data={dataTestimonial2} />

            <Counter />

            <Sale01 />
            
        </div>
    );
}

export default HomeOne;