
import img1 from '../images/thumbnail/tmb-01.png';
import img2 from '../images/thumbnail/tmb-02.png';
import img3 from '../images/thumbnail/tmb-03.png';
import img4 from '../images/thumbnail/tmb-04.png';
import img5 from '../images/thumbnail/tmb-05.png';
import img6 from '../images/thumbnail/tmb-06.png';




const dataBlog = [
    {
        id: 1,
        img: img1,
        category: 'News',
        title: 'Amata Siam - Thai Amulet',
        user:'Administrator',
        time: 'Feb 03, 2024',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 2,
        img: img2,
        category: 'News',
        title: 'Amata Siam - Thai Amulet',
        user:'Administrator',
        time: 'Feb 03, 2024',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 3,
        img: img3,
        category: 'News',
        title: 'Amata Siam - Thai Amulet',
        user:'Administrator',
        time: 'Feb 03, 2024',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 4,
        img: img4,
        category: 'News',
        title: 'Amata Siam - Thai Amulet',
        user:'Administrator',
        time: 'Feb 03, 2024'
    },
    {
        id: 5,
        img: img5,
        category: 'News',
        title: 'Amata Siam - Thai Amulet',
        user:'Administrator',
        time: 'Feb 03, 2024'
    },
    {
        id: 6,
        img: img6,
        category: 'News',
        title: 'Amata Siam - Thai Amulet',
        user:'Administrator',
        time: 'Feb 03, 2024'
    },
   
]

export default dataBlog;