import React , {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';
import ImgAmulet23 from '../assets/images/amulets/ASSC023/31_dca8cc1b.jpg'
import ImgAmulet24 from '../assets/images/amulets/ASSC024/33_94c7eaf6.jpg'
import ImgAmulet26 from '../assets/images/amulets/ASSC026/13_eb0d2d55.jpg'
import ImgAmulet89 from '../assets/images/amulets/ASSC089/53_ea1ffafa.jpg'
import ImgAmulet20 from '../assets/images/amulets/ASSC020/05_87da7590.jpg'
import ImgAmulet32 from '../assets/images/amulets/ASSC032/25_effd9302.jpg'
import ImgAmulet04 from '../assets/images/amulets/ASSC004/39_2cc0f0ae.jpg'
import ImgAmulet177 from '../assets/images/amulets/ASSC177/57_36bc2dfe.jpg'
import ImgAmulet206 from '../assets/images/amulets/ASSC206/06_0ddcb272.jpg'
import ImgAmulet61 from '../assets/images/amulets/ASSC061/25_27cd00ce.jpg'
import ImgAmuletA01 from '../assets/images/amulets/A001/16_4bd06b3e.jpg'
import ImgAmulet071 from '../assets/images/amulets/ASSC071/37_66c4b806.jpg'
import ImgAmulet205 from '../assets/images/amulets/ASSC205/10_2b65bca4.jpg'
import ImgAmulet291 from '../assets/images/amulets/ASSC291/28_0659ba8d.jpg'
import ImgAmulet025 from '../assets/images/amulets/ASSC025/56_9007ad6e.jpg'
import ImgAmulet64 from '../assets/images/amulets/ASSC064/58_7d422505.jpg'
import ImgAmulet84 from '../assets/images/amulets/ASSC084/48_490613c3.jpg'

function Amulets(props) {

    const [dataAmulet] = useState([
        {
            id: 'ASSC023',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet23,
            price: '20,000,000',
        },
        {
            id: 'ASSC024',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet24,
            price: '20,000,000',
        },
        {
            id: 'ASSC071',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ยอดขุนพล',
            img: ImgAmulet071,
            price: '50,000,000',
        },
        {
            id: 'ASSC089',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet89,
            price: '20,000,000',
        },
        {
            id: 'ASSC020',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet20,
            price: '20,000,000',
        },
        {
            id: 'ASSC206',
            name: 'พระปิดตา พิมพ์ หลังแบบ',
            img: ImgAmulet206,
            price: '1,000,000',
        },
        {
            id: 'ASSC026',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet26,
            price: '30,000,000',
        },
        {
            id: 'ASSC177',
            name: 'พระสมเด็จ วัดเกษไชโย พิมพ์เจ็ดชั้น',
            img: ImgAmulet177,
            price: '20,000,000',
        },
        {
            id: 'ASSC089',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet89,
            price: '30,000,000',
        },
        {
            id: 'ASSC004',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet04,
            price: '20,000,000',
        },
        {
            id: 'ASSC205',
            name: 'หลวงปู่ทวด เนื้อว่าน ปี พ.ศ.๒๔๙๗',
            img: ImgAmulet205,
            price: '1,500,000',
        },
        {
            id: 'ASSC032',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet32,
            price: '20,000,000',
        },
        {
            id: 'ASSC061',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet61,
            price: '10,000,000',
        },
        {
            id: 'A001',
            name: 'ขุนแผน พิมพ์ทรงพลใหญ่ วัดบ้านกร่าง',
            img: ImgAmuletA01,
            price: '500,000',
        },
        {
            id: 'ASSC291',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet291,
            price: '5,000,000',
        },
        {
            id: 'ASSC025',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet025,
            price: '20,000,000',
        },
        {
            id: 'ASSC064',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet64,
            price: '20,000,000',
        },
        {
            id: 'ASSC084',
            name: 'พระสมเด็จ วัดระฆังฯ พิมพ์ใหญ่',
            img: ImgAmulet84,
            price: '30,000,000',
        },
      
      
    ]);

   return (
        <div>
            <PageTitle heading='Thai Amulets' title='Amulets' />

            <section className="buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">
                 
                            <div className="content-inner">
                                <div className="top">
                                <h3 className='center'>Amata Siam</h3>
                                <p className='center'>Premium Market</p>
                                </div>

                                <div className="main">
                                <div className='row'>

                                {
                                    dataAmulet.map(Amu => (
                                      
                                        <div className='col-md-3 col-6 mb-4' key={Amu.id}>
                                        <div className="card">
                                            <img className="card-img-top" src={Amu.img} alt="Premium Thai Amulet"/>
                                            <div className="card-body">                                      
                                                <p className="card-text text-black">#{Amu.id}</p>
                                                <p className="card-text text-black">{Amu.name}</p>
                                                <p className="card-text">ราคาประเมิน <span className="text-primary fw-bold">{Amu.price}</span> ฿</p>     

                                                <Link to="https://line.me/R/ti/p/@769jnlmh" target="_blank" rel="noreferrer"><button className='btn btn-info btn-sm mt-2'>สนใจติดต่อ</button></Link>                                            
                                                
                                            </div>
                                            </div>
                                        </div>
                                    ))
                                }


                                </div>
                                </div>
                            </div>
                           

                    </div>
                </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default Amulets;