import React , {useState} from 'react';

import img from '../../assets/images/layout/Picture8.png'



function Trading(props) {
    

    const [dataBlock] = useState(
        {
            heading: 'เทคโนโลยีการตรวจสอบ',
            desc : 'ด้วยเครื่องตรวจสอบมาตรฐานสากล'
        }
    )
    return (
        <section className="trading">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="trading__image mt-107">
                    <img src={img} alt="ASC" />
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div
                    className="trading__content"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    >
                    <h3 className="heading">{dataBlock.heading}</h3>
                    <p className="desc fs-20">
                        {dataBlock.desc}
                    </p>

                    <ul className="list-steps">
                       <p>
                       การตรวจสอบอายุโบราณวัตถุด้วยเทคนิคการเปล่งแสง (Luminescence dating)
การตรวจสอบอายุของโบราณวัตถุโดยใช้เทคนิคการเปล่งแสงจะต้องทราบจำนวนอิเล็กตรอนในหลุมกักเก็บและอัตราการแผ่รังสีต่อปีของสิ่งแวดล้อมที่ทำให้อิเล็กตรอนเข้าไปฝังตัวในหลุมกักเก็บ ซึ่งสมการที่ใช้หาอายุด้วยเทคนิคนี้ 


                       </p>
                        
                    </ul>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Trading;