import React from 'react';
import Sale01 from '../components/sale/Sale01';
import { useState , useEffect } from 'react';

import {  Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageTitle from '../components/pagetitle';
import Cookies from 'js-cookie';


function Register(props) {
    const [myWalletAddress, setWalletAddress] = useState("");
    const sessionWallet = Cookies.get('wallet');
    
    useEffect( () => {
        setWalletAddress(sessionWallet);              
     },[])

    return (
        <div>

            <PageTitle heading='Registration' title='Register' />



            <section className="register">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">Register To ASC Land</h3>
                    <p className="desc fs-20">
                    {myWalletAddress && myWalletAddress.length > 0 ? myWalletAddress : "Please connect metamask wallet before registration"}
                    
                    </p>
                    </div>
                </div>
                <div className="col-md-12">
                <Tabs>                  

                    <TabPanel>
                        <div className="content-inner">
                            <form>
                                <div className="form-group">
                                <label>FullName
                                    <span className="fs-14">(FirstName - LastName)</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    
                                />
                                </div>
                                <div className="form-group">
                                <label>NickName</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="name"
                                />
                                </div>
                                <div className="form-group">
                                <label for="exampleInputEmail1">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    placeholder="Please fill in the email form."
                                />
                                </div>
                                <div className="form-group">
                                <label>
                                    Secret-Key
                                    <span>(6-10 Numbers)</span>
                                    </label>
                                <input
                                    type="password"
                                    className="form-control mb-10"
                                    placeholder="Please enter Numbers."
                                />                              
                                </div>
                               
                                <div className="form-group">
                                <label>Phone
                                    <span className="fs-14">(Enter numbers only)</span></label>
                               
                                 <div className="sl">                                
                                <select className="form-control" id="select_code" >
                                    <option value="+66">TH  +66</option> 
                                    <option value="+65">SG  +65</option> 
                                    <option value="+86">CN  +86</option>
                                    <option value="+852">HK  +852</option>
                                    <option value="+886">TW  +886</option>
                                    <option value="+1">USA  +1</option> 
                                    <option value="+44">UK  +44</option>                                     
                                </select>
                                <input type="tel" className="form-control" placeholder="Phone number (without '-')" />
                                </div>
                                </div>

                                <div className="form-group">
                                <label>Referral Code </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Please enter your invitation code."
                                />
                                </div>

                                <button type="submit" className="btn-action">
                                Pre-Registration
                                </button>
                                <div className="bottom">                                
                                </div>
                            </form>
                        </div>
                    </TabPanel>                

                </Tabs> 

                </div>
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default Register;