import HomeOne from "./HomeOne";
import AboutUs from "./AboutUs";
import BuyCrypto from "./BuyCrypto";
import BuyCryptoConfirm from "./BuyCryptoConfirm";
import BuyCryptoDetails from "./BuyCryptoDetails";
import Staking from "./Staking";
import AssetToken from "./AssetToken";
import NftMarket from "./NFTmarket";
import Amulets from "./Amulets";
import WhitePaper from "./WhitePaper";
import SellSelect from "./SellSelect";
import SellCryptoAmount from "./SellCryptoAmount";
import SellCryptoConfirm from "./SellCryptoConfirm";
import SellCryptoDetails from "./SellCryptoDetails";
import BlogNews from "./Blog-news";
import BlogList from "./BlogList";
import BlogDetails from "./BlogDetails";
import Wallet from "./Wallet";
import UserProfile from "./UserProfile";
import Login from "./Login";
import Register from "./Register";
import Contact from "./Contact";
import Faq from "./Faq";
import Airdrop from "./airdrop";
import AirdropList from "./airdrop-list";
import Event_success from "./event-success";


const routes = [
  { path: '/', component: <HomeOne />},
  { path: '/about-us', component: <AboutUs />},
  { path: '/buy-coin', component: <BuyCrypto />},
  { path: '/buy-confirm', component: <BuyCryptoConfirm />},
  { path: '/buy-details', component: <BuyCryptoDetails />},
  { path: '/staking', component: <Staking />},
  { path: '/asset-token', component: <AssetToken />},
  { path: '/nft', component: <NftMarket />},
  { path: '/amulets', component: <Amulets />},
  { path: '/white-paper', component: <WhitePaper />},
  { path: '/sell-select', component: <SellSelect />},
  { path: '/sell-amount', component: <SellCryptoAmount />},
  { path: '/sell-confirm', component: <SellCryptoConfirm />},
  { path: '/sell-details', component: <SellCryptoDetails />},
  { path: '/news', component: <BlogNews />},
  { path: '/blog-list', component: <BlogList />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/wallet', component: <Wallet />},
  { path: '/user-profile', component: <UserProfile />},
  { path: '/login', component: <Login />},
  { path: '/register', component: <Register />},
  { path: '/airdrop', component: <Airdrop />},
  { path: '/airdrop-list', component: <AirdropList />},
  { path: '/event-success', component: <Event_success />},
  { path: '/contact', component: <Contact />},
  { path: '/faq', component: <Faq />},


]

export default routes;