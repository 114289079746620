import React , {useState,useRef } from 'react';
import PropTypes from 'prop-types';
import {Link } from 'react-router-dom';

import img1 from '../../assets/images/logo/Acoin-512.png'

import { FaRegCopy } from "react-icons/fa";
import { Navigation, Scrollbar, A11y   } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import {Button} from '../button';



Banner01.propTypes = {
    data: PropTypes.array,
};

function Banner01(props) {
    const [textToCopy] = useState('0x912093FDE022138c3013BFD4748a015aD69b2292');
    const [copyStatus, setCopyStatus] = useState(false); 

    const [show, setShow] = useState(false);
    const target = useRef(null);
   
    const onCopyText = () => {
        setCopyStatus(true);
        setShow(true);
        setTimeout(() => setCopyStatus(false), 2000);
        setTimeout(() => setShow(false), 2000);
      };

    const {data} = props;

    const [dataBlock] = useState(
        {
            title: 'Digital Assets Thai Exclusive Amulets',
            desc : 'ASC Coin is Token created from the blockchain BEP20 used for investor and community.',
            title2: 'Our Partners'
        }
    );
    return (
        <section className="banner">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__content">
                        <h2 className="title">{dataBlock.title}</h2>
                        <p className="fs-20 desc">
                            {dataBlock.desc}
                        </p> 
                                               
                        <div className="input-group mt-3">                            
                            <div className="input-group-prepend"> 
                            <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
                             <button className="btn btn-outline-secondary" type="button" ref={target} ><FaRegCopy /> Address</button>
                            </CopyToClipboard>                  
                            </div>
                            <Overlay target={target.current} show={show} placement="top">
                                {(props) => (
                                <Tooltip id="overlay" {...props}>
                                    Copied.
                                </Tooltip>
                                )}
                            </Overlay>
                            <input type="text" className="form-control" value="0x912093FDE022138c3013BFD4748a015aD69b2292" aria-label="" aria-describedby="basic-addon1" disabled />
                           
                        </div>

                        <Button title='BSC Scan' path='https://bscscan.com/token/0x912093fde022138c3013bfd4748a015ad69b2292' />
                       
                        <Link to='/buy-coin' className='btn-action mx-3'>Buy ASC Coin</Link> 
                        
                        <div className="partner">
                            <h6>{dataBlock.title2}</h6>
                            <div className="partner__list">

                            <Swiper
                                modules={[Navigation,  Scrollbar, A11y ]}
                                    spaceBetween={65}
                                    slidesPerView={4}
                                    className="swiper-partner"
                                >
                                {
                                    data.map(idx => (
                                        <SwiperSlide key={idx.id}>
                                            <Link to="#">
                                                <img src={idx.img} alt="ASC"/>
                                            </Link>
                                        </SwiperSlide>
                                        
                                    ))
                                }
                            </Swiper>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-12">
                        <div className="banner__image">
                        <img src={img1} alt="ASC" />
                        </div>
                    </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner01;