import React , { useState , useEffect } from 'react';

import Cookies from 'js-cookie';
import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import { Dropdown } from 'react-bootstrap';

import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import logodark from '../../assets/images/logo/logo-dark.png';
import avt from '../../assets/images/avt/avt-01.jpg';
import DarkMode from './DarkMode';

import icon1 from '../../assets/images/flags/us.jpg'
import icon2 from '../../assets/images/flags/TH-Flag.png'
import icon3 from '../../assets/images/flags/CN-Flag.png'
// import Button from '../button';



const Header = () => {

    const [myWalletAddress, setWalletAddress] = useState("");
   
    useEffect( () => {
        metaConnected();
        changeWallet();

    },[])

    const connectWallet = async() =>{
        if(typeof window !== "undefined" && window.ethereum !== "undefined"){
            try{
              const accounts = await window.ethereum.request({method: "eth_requestAccounts"});   
              if(accounts.length > 0){          
              Cookies.set('wallet', accounts[0], { expires: 1 });
              setWalletAddress(accounts[0]);
            }
            }catch(err){
              console.error(err.message)
            }
        }
    }   
    

        const metaLogout = async() =>{    
                if(typeof window !== "undefined" && window.ethereum !== "undefined"){        
                    await window.ethereum.request({
                        method: "wallet_revokePermissions", 
                        params: [
                                {
                                "eth_accounts": {}
                                }
                            ]
                    }); 
                           
                    Cookies.remove('wallet');
                   
            }
        }

        const changeWallet = async() =>{
            if(typeof window !== "undefined" && window.ethereum !== "undefined"){        
                window.ethereum.on("accountsChanged",(accounts) =>{setWalletAddress(accounts[0]);});    
              }
          }

          const metaConnected = async() =>{
            if(typeof window !== "undefined" && window.ethereum !== "undefined"){
                try{
                  const accounts = await window.ethereum.request({method: "eth_accounts"});
                  if(accounts.length > 0){
                    setWalletAddress(accounts[0]);
                  }
                    
                }catch(err){
                  console.error(err.message)
                }
            }
        }   



    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container-fluid">
                <div className="row">
                <div className="col-12">
                    <div className="header__body d-flex justify-content-between">
                    <div className="header__left">
                        <div className="logo">
                        <NavLink to='/' className="light">
                            <img
                            src={logo}
                            alt="ASC logo"
                            />
                        </NavLink>
                        <NavLink to='/' className="dark">
                            <img
                            src={logodark}
                            alt="ASC logo"
                            />
                        </NavLink>
                        </div>
                        <div className="left__main">
                            <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                <ul id="menu-primary-menu" className="menu">
                                {
                                    menus.map((data,idx) => (
                                        <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} 
                                        
                                        >
                                            <Link to={data.links}>{data.name}</Link>
                                            {
                                                data.namesub &&
                                                <ul className="sub-menu">
                                                    {
                                                        data.namesub.map((submenu) => (
                                                            <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                            
                                        </li>
                                    ))
                                }                               
                                </ul>
                            </nav>  
                        </div>
                        
                    </div>

                    <div className="header__right">   
                    <DarkMode />   

                            <Dropdown>
                                <Dropdown.Toggle >
                                    TH/THB  
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                <Dropdown.Item href="#">
                                    <Link
                                        to="#"
                                        className="dropdown-item notify-item language"
                                        data-lang="th"
                                        >
                                        <img
                                            src={icon2}
                                            alt="th-flag"
                                            className="mx-1"                                          
                                            height="12"
                                        />
                                        <span className="align-middle">TH/THB</span>
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <Link
                                        to="#"
                                        className="dropdown-item notify-item language"
                                        data-lang="en"
                                        >
                                        <img
                                            src={icon1}
                                            alt="en-flag"
                                            className="mx-1"                                           
                                            height="12"
                                        />
                                        <span className="align-middle">EN/USD</span>
                                    </Link>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <Link
                                        to="#"
                                        className="dropdown-item notify-item language"
                                        data-lang="cn"
                                        >
                                        <img
                                            src={icon3}
                                            alt="cn-flag"
                                            className="mx-1"
                                            height="12"
                                        />
                                        <span className="align-middle">CN/CNY</span>
                                    </Link>
                                </Dropdown.Item>   
                                </Dropdown.Menu>
                            </Dropdown>
                                               

                        <div className="wallet">
                           {
                            myWalletAddress && myWalletAddress.length > 0 ? 
                            <Link to="/wallet" >{myWalletAddress.substring(0,6)+"....."+myWalletAddress.substring(38)}</Link> : 
                            <Link to="#" onClick={connectWallet}>Connect...Wallet</Link>
                            }
                        </div>
                        {
                        myWalletAddress && myWalletAddress.length > 0 ?
                        <Dropdown className='user'>
                                <Dropdown.Toggle >
                                    <img src={avt} alt="Avatar User" />  
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                <Dropdown.Item href="user-profile">
                                    <Link className="dropdown-item" to="/user-profile"><i className="bx bx-user font-size-16 align-middle me-1"></i>
                                    <span>Profile</span></Link>
                                </Dropdown.Item>
                                <Dropdown.Item href="wallet">
                                    <Link className="dropdown-item" to="/wallet"><i
                                        className="bx bx-wallet font-size-16 align-middle me-1"
                                    ></i>
                                    <span>My Wallet</span></Link>
                                </Dropdown.Item>                               
                                <Dropdown.Item href="#" onClick={metaLogout}>
                                    <Link to="#" className="dropdown-item text-danger" onClick={metaLogout}>
                                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" ></i>
                                        <span>Logout</span>
                                    </Link>
                                </Dropdown.Item>
                                
                                </Dropdown.Menu>
                            </Dropdown>
                        : ""
                        }

                           <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </header>
       
    );
}

export default Header;