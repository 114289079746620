import React , {useState} from 'react';


import img from '../../assets/images/logo/amata-512.png'
import { MdPictureAsPdf } from "react-icons/md";
import {Link} from 'react-router-dom'

function Banner03(props) {

    const [dataBlock] = useState(
        {
            title: 'ASC COIN PROJECT',
            sub: 'โดย',
            desc : 'บริษัท อมตะ สยาม คอร์ปอเรชั่น จำกัด',
        }
    );
    return (
        <section className="banner">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="banner__content">
                    <h2 className="title">{dataBlock.title}</h2>
                    <p className="fs-20 desc">{dataBlock.sub}</p>
                    <p className="fs-20 desc mb-28">                        
                        {dataBlock.desc}
                    </p>
                                     
                    </div>
                    <div className="banner__image">
                        <img src={img} alt="ASC" />
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Banner03;