import React , {useEffect, useState} from 'react';
import Cookies from 'js-cookie';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';

import imgCert500 from '../assets/images/layout/cert-500m.jpg'


function AssetToken(props) {

    const [thbValue, setThbValue] = useState(0);
    const [ascValue, setAscValue] = useState(0);
    
    const sessionWallet = Cookies.get('wallet');
    const [myWalletAddress, setWalletAddress] = useState("");
   
    
    useEffect( () => {
        setWalletAddress(sessionWallet);              
     },[])

    useEffect( () => {      
        setThbValue(ascValue*100);

    },[ascValue])

    return (
        <div>
            <PageTitle heading='Tokenization' title='Asset-Backed' />

            <section className="buy-crypto flat-tabs">
                <div className="container">
                    <div className="row">                   
                            <div className="content-inner">  

                                <div className="top">
                                    <center><img src={imgCert500} alt="500m" width="50%"/></center>
                                </div>                        

                                <div className="main">
                              
                                </div>
                            </div>
                         

                    </div>
                </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default AssetToken;