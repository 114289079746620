import React from 'react';
import Axios from 'axios';
import { useState } from 'react';
import {  Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageTitle from '../components/pagetitle';



function Airdrop(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [wallet, setWallet] = useState("");
    const [code, setCode] = useState("AMATA-SOFTPOWER-020524");
    const [registForm, setRegistForm] = useState([]);


    const sendRegister = () =>{
        Axios.post('https://xn7m98wq-5020.asse.devtunnels.ms/create',{
            name: name,
            email: email,
            phone: phone,
            wallet: wallet,
            code: code
        }).then(()=>{
            setRegistForm([
                ...registForm,
                {
                    name: name,
                    email: email,
                    phone: phone,
                    wallet: wallet,
                    code: code
                }
            ])       
        })       
    }

     
                                 
      



    return (
        <div>

            <PageTitle heading='Airdrop Event ' title='Airdrop' />



            <section className="register">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">Register get free ASC tokens</h3>
                    <p className="desc fs-20">
                        ลงทะเบียนเพื่อรับเหรียญ ASC Coin <br/>(จำกัดสิทธิ์เฉพาะผู้เข้าร่วมงาน)                  
                    </p>
                    </div>
                </div>
                <div className="col-md-12">
                <Tabs>                  

                    <TabPanel>
                        <div className="content-inner">
                            <form onSubmit={sendRegister} action='/event-success'>
                                <div className="form-group">
                                <label>ชื่อ-นามสกุล
                                    <span className="fs-14"> (Your FullName)</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(event) => {setName(event.target.value)}}
                                    value={name}
                                    maxlength="50"
                                />
                                </div>                              
                                <div className="form-group">
                                <label>อีเมล์
                                    <span className="fs-14"> (Your Email)</span></label>
                                <input
                                    type="email"
                                    className="form-control"
                                    onChange={(event) => {setEmail(event.target.value)}}
                                    value={email}
                                    maxlength="65"  
                                    required                                                                      
                                />
                                </div>
                            
                                <div className="form-group">
                                <label>เบอร์โทรศัพท์
                                    <span className="fs-14"> (Phone numbers only)</span></label>                                           
                                <input type="tel" 
                                    className="form-control" 
                                    onChange={(event) => {setPhone(event.target.value)}}
                                    value={phone}
                                    maxlength="10"
                                    required
                                    />
                                </div>
                                

                                <hr/>
                                <div className="form-group">
                                <label>Address Wallet
                                    <span className="fs-14"> (หากท่านยังไม่มีให้เว้นว่างไว้)</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control" 
                                    onChange={(event) => {setWallet(event.target.value)}}
                                    value={wallet}  
                                    maxlength="80"                                 
                                />
                                </div>

                                <div className="form-group">
                                <label>Airdrop Code</label>
                                <input
                                    type="text"
                                    className="form-control"                                  
                                    onChange={(event) => {setCode(event.target.value)}}
                                    value={code} 
                                    maxlength="30"
                                />
                                </div>

                                <button type='submit' className="btn-action">
                                    ลงทะเบียนรับสิทธิ์
                                </button>
                                <div className="bottom">                                
                                </div>
                            </form>
                        </div>
                    </TabPanel>                

                </Tabs> 

                </div>
                </div>
            </div>
            </section>
          
            
        </div>
    );
}

export default Airdrop;