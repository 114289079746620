import React , {useState} from 'react';
import PropTypes from 'prop-types';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


import img from '../../assets/images/layout/testimonials.png'
import user1 from '../../assets/images/avt/use-02.png'
import user2 from '../../assets/images/avt/use-02.png'
import user3 from '../../assets/images/avt/use-03.png'
import user4 from '../../assets/images/avt/use-04.png'
import user5 from '../../assets/images/avt/use-05.png'
import user6 from '../../assets/images/avt/use-03.png'


Testimonial02.propTypes = {
    data : PropTypes.array
};

function Testimonial02(props) {

    const [listUser] = useState([
        {
            id: 1,
            img: user1
        },
        {
            id: 2,
            img: user2
        },
        {
            id: 3,
            img: user3
        },
        {
            id: 4,
            img: user4
        },
        {
            id: 5,
            img: user5
        },
        {
            id: 6,
            img: user6
        },
    ])

  

    return (
        <section className="testimonials-2">
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-md-12 left">
                    <div className="block-text center">
                    <h3 className="heading">Trade all the World</h3>
                    <p className="desc">
                        เรามีแผนการขยายตลาดการค้าพระเครื่องไทยไปสู่ระดับโลก เพื่อให้ผู้คนมากมายได้รู้จักและเข้าใจในวัฒนธรรมและศิลปะอันล้ำค่าของคนไทย
                    </p>
                    </div>
             

                </div>
                <div className="col-xl-6 col-md-12">
                    <div className="map-testimonial">
                    <img
                        className="map"
                        src={img}
                        alt="world-map"                       
                    />

                    <div className="swiper swiper-thumb2">
                        <div className="swiper-wrapper list-img">

                        {
                            listUser.map(idx => (
                                <div key={idx.id} className="swiper-slide">
                                    <div><img src={idx.img} className='rounded-circle' alt="" /></div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
    );
}

export default Testimonial02;