import React , {useState, useEffect} from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Cookies from 'js-cookie';
import 'react-tabs/style/react-tabs.css';

import Sale01 from '../components/sale/Sale01';
import PageTitle from '../components/pagetitle';

import { AiOutlineCodeSandbox } from "react-icons/ai";
import { FaRegCopy } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import QR_pay from '../assets/images/payment/qr-471B2.jpg'
import BSCchain from '../assets/images/coin/bsc-chain.png'

function BuyCryptoConfirm(props) {

    const [myWalletAddress, setWalletAddress] = useState("");
    const sessionWallet = Cookies.get('wallet');
    
    useEffect( () => {
        setWalletAddress(sessionWallet);              
     },[])

    const [textToCopy, setTextToCopy] = useState('0x49Db2eEE61395cCC6AAa48B1fE3318d9257471B2');
    const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied

    const onCopyText = () => {
      setCopyStatus(true);
      setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
    };


    const [dataCryptoTab] = useState([
        {
            id: 1,
            title: 'Buy Crypto',
        },      
    ]);

    return (
        <div>

            <PageTitle heading='Buy Crypto' title='Buy Crypto' />

            <section className="buy-crypto s1 flat-tabs">
            <div className="container">
                <div className="row">
                <Tabs>
                            <TabList>


                            </TabList>

                            <TabPanel>
                                <div className="content-inner buy-crypto__main">
                                    <div className="top">
                                    <ul className="top-list">
                                        <li className="done">
                                        <h6>
                                            <span>
                                            <svg
                                                width="10"
                                                height="8"
                                                viewBox="0 0 10 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M1 3.99967L3.66667 6.66634L9 1.33301"
                                                stroke="white"
                                                strokeWidth="2"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                />
                                            </svg> </span>
                                            Select currency
                                        </h6>
                                        </li>
                                        <li className="active">
                                        <h6><span></span>Confirm Payment</h6>
                                        </li>
                                        <li>
                                        <h6><span></span>Order Details</h6>
                                        </li>
                                    </ul>
                                    </div>

                                    <div className="main info">
                                    <h6>Confirm Information</h6>
                                    <p className="desc">
                                        You are about to Receive 0.00 ASC to Wallet
                                    </p>

                                    <ul className="list">
                                        <li>
                                        <div className="icon">
                                            <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M15.8333 4.16667H4.16665C3.24617 4.16667 2.49998 4.91286 2.49998 5.83333V14.1667C2.49998 15.0871 3.24617 15.8333 4.16665 15.8333H15.8333C16.7538 15.8333 17.5 15.0871 17.5 14.1667V5.83333C17.5 4.91286 16.7538 4.16667 15.8333 4.16667ZM4.16665 2.5C2.3257 2.5 0.833313 3.99238 0.833313 5.83333V14.1667C0.833313 16.0076 2.3257 17.5 4.16665 17.5H15.8333C17.6743 17.5 19.1666 16.0076 19.1666 14.1667V5.83333C19.1666 3.99238 17.6743 2.5 15.8333 2.5H4.16665Z"
                                                fill="white"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M10.8333 9.99967C10.8333 7.69849 12.6988 5.83301 15 5.83301H18.3333C18.7935 5.83301 19.1666 6.2061 19.1666 6.66634C19.1666 7.12658 18.7935 7.49967 18.3333 7.49967H15C13.6193 7.49967 12.5 8.61896 12.5 9.99967C12.5 11.3804 13.6193 12.4997 15 12.4997H18.3333C18.7935 12.4997 19.1666 12.8728 19.1666 13.333C19.1666 13.7932 18.7935 14.1663 18.3333 14.1663H15C12.6988 14.1663 10.8333 12.3009 10.8333 9.99967Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M15.8334 10.0003C15.8334 10.4606 15.4603 10.8337 15 10.8337C14.5398 10.8337 14.1667 10.4606 14.1667 10.0003C14.1667 9.54009 14.5398 9.16699 15 9.16699C15.4603 9.16699 15.8334 9.54009 15.8334 10.0003Z"
                                                fill="white"
                                            />
                                            </svg>
                                        </div>
                                        <div className="content">
                                            <p>Pay</p>
                                            <h6 className="price">0.00 THB</h6>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="icon">
                                            <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M15.8333 4.16667H4.16665C3.24617 4.16667 2.49998 4.91286 2.49998 5.83333V14.1667C2.49998 15.0871 3.24617 15.8333 4.16665 15.8333H15.8333C16.7538 15.8333 17.5 15.0871 17.5 14.1667V5.83333C17.5 4.91286 16.7538 4.16667 15.8333 4.16667ZM4.16665 2.5C2.3257 2.5 0.833313 3.99238 0.833313 5.83333V14.1667C0.833313 16.0076 2.3257 17.5 4.16665 17.5H15.8333C17.6743 17.5 19.1666 16.0076 19.1666 14.1667V5.83333C19.1666 3.99238 17.6743 2.5 15.8333 2.5H4.16665Z"
                                                fill="white"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M10.8333 9.99967C10.8333 7.69849 12.6988 5.83301 15 5.83301H18.3333C18.7935 5.83301 19.1666 6.2061 19.1666 6.66634C19.1666 7.12658 18.7935 7.49967 18.3333 7.49967H15C13.6193 7.49967 12.5 8.61896 12.5 9.99967C12.5 11.3804 13.6193 12.4997 15 12.4997H18.3333C18.7935 12.4997 19.1666 12.8728 19.1666 13.333C19.1666 13.7932 18.7935 14.1663 18.3333 14.1663H15C12.6988 14.1663 10.8333 12.3009 10.8333 9.99967Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M15.8334 10.0003C15.8334 10.4606 15.4603 10.8337 15 10.8337C14.5398 10.8337 14.1667 10.4606 14.1667 10.0003C14.1667 9.54009 14.5398 9.16699 15 9.16699C15.4603 9.16699 15.8334 9.54009 15.8334 10.0003Z"
                                                fill="white"
                                            />
                                            </svg>
                                        </div>
                                        <div className="content">
                                            <p>Get</p>
                                            <h6 className="price">0.00 ASC</h6>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="icon text-size-20">
                                           <p className='h5 text-white'>
                                                <AiOutlineCodeSandbox />
                                            </p>
                                        </div>
                                        <div className="content">
                                            <p>Status</p>
                                            <h6 className="price">Pending</h6>
                                        </div>
                                        </li>
                                    </ul>
                                    </div>
                                    <div className="main details">
                                    <h6>Payment Details</h6>
                                    <p className="desc">[Crypto Pay]  <img src={BSCchain} alt='BSCchain'  width={28}/><span> BSC Chain</span></p>
                                   
                                    <div className="input-group mt-3">                            
                                        <div className="input-group-prepend"> 
                                        <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
                                            <button className="btn btn-outline-secondary" type="button"><FaRegCopy /> Copy</button>
                                        </CopyToClipboard>                  
                                        </div>
                                        <input type="text" className="form-control" value="0x49Db2eEE61395cCC6AAa48B1fE3318d9257471B2" aria-label="" aria-describedby="basic-addon1" disabled />                                                                          
                                    </div>  
                                    <div className='images center my-5'><img src={QR_pay} alt='QR pay' /> </div>
                                         
                                    <form action="/buy-details">
                                        <p className="desc">[User Details]</p>

                                        <div className="form-group">
                                        <label>Account Name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value=""
                                            disabled
                                        />
                                        </div>
                                        <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value=""
                                            disabled
                                        />
                                        </div>
                                        <div className="form-group">
                                        <label>Address Wallet</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={myWalletAddress}
                                            disabled
                                        />
                                        </div>        

                                        <div className="form-group">
                                        <label>Secret Key</label>
                                        <input
                                            className="form-control"
                                            type="password"                                                                                       
                                        />
                                        </div>       

                                        <div className="group-button">
                                        <Link to="/buy-select" className="cancel btn-action-3">Cancel</Link>
                                        <button className="submit btn-action">Confirm</button>
                                        </div>
                                    </form>
                                    </div>
                                </div>
                            </TabPanel>
                        
                            

                    </Tabs> 
                    
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default BuyCryptoConfirm;