import React from 'react';
import 'react-tabs/style/react-tabs.css';
import ImgCheck from '../assets/images/icon/check-mark.png'


function Event_success() {
    


    return (
        <div>


            <section className="register">
            <div className="container">
                <div className="row">
                <div className="col-md-12 mb-50">
                    <div className="block-text center">
                    <h3 className="heading">Register Successfully.</h3>
                    <p className="desc fs-20">
                        ทำการลงทะเบียนสำเร็จแล้ว                 
                    </p>
                    </div>
                </div>
                <br/>               
                <div className="center">                         
                    <img src={ImgCheck} width={180} alt="Check icon"/>
                </div>
                </div>
            </div>
            </section>

        
            
        </div>
    );
}

export default Event_success;