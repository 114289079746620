import React , {useState, useEffect} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';

import './styles.scss';

import {Link} from 'react-router-dom';


function Crypto01() {

    const [coinData, setCoinData] = useState([]);

    const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum%2Cbinancecoin%2Ctether&order=market_cap_desc';
    const options = {
      method: 'GET',
      accept: 'application/json',
      headers: {'x-cg-demo-api-key': 'CG-aRSKtaHhr4mATiwp92wR4Ad8'}
    };    


    useEffect(() => {
        async function fetchData() {
          
          await axios.get(url,options)
          .then(response => {setCoinData(response.data)})
          .catch(error => {console.log(error)});
          
        }
        fetchData();
      }, []);  

    const [dataCrytoTab] = useState([
        {
            id: 1,
            title: 'Crypto',
        },
        {
            id: 2,
            title: 'DeFi',
        },
        {
            id: 3,
            title: 'ASC',
        },
       
    ])

    
    return (
        <section className="crypto" data-aos="fade-up" data-aos-duration="1000">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="crypto__main">
                        <Tabs>
                            <TabList>
                                {
                                    dataCrytoTab.map(idx => (
                                        <Tab key={idx.id}>{idx.title}</Tab>
                                    ))
                                }

                            </TabList>

                            {
                                dataCrytoTab.map(data => (
                                    <TabPanel key={data.id}>
                                        <div className="content-inner">
                                            {
                                                coinData.map((data,i) => (
                                                    <div key={i} className={`crypto-box ${data.active}`}>
                                                        <div className="top">
                                                            <Link to="#">
                                                            <img src={data.image} alt="icon" width={28}/>
                                                            <span>{data.name}</span>
                                                            <span className="unit">{data.symbol}</span></Link>
                                                        </div>
                                                        <h6 className="price">{data.current_price.toLocaleString()} USD</h6>
                                                        <div className="bottom">
                                                            <p className="badge bg-secondary">24h</p>
                                                            <p className={`sale ${data.price_change_percentage_24h< 0 ? 'critical' :'success'}`}>{data.price_change_24h.toLocaleString()}</p>
                                                            <p className={`sale ${data.price_change_percentage_24h< 0 ? 'critical' :'success'}`}>{data.price_change_percentage_24h.toLocaleString()}%</p>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        
                                        </div>
                                    </TabPanel>
                                ))
                            }
                            

                        </Tabs> 
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Crypto01;