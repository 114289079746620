import React from 'react';
import Axios from 'axios';
import { format } from 'date-fns';
import { useState } from 'react';
import 'react-tabs/style/react-tabs.css';



function AirdropList() {
    
    const [ listAirdrop, setListAirdrop ] = useState([]);

    const getApiEvent = () =>{
        Axios.get('https://xn7m98wq-5020.asse.devtunnels.ms/api').then((response)=>{
            setListAirdrop(response.data);
        })
    }


    return (
        <div>


            <section className="register">
            <div className="container">
                <div className="row">
                <div className="col-md-12 mb-50">
                    <div className="block-text center">
                    <h3 className="heading">List User get free ASC</h3>
                    <p className="desc fs-20">
                        รายชื่อผู้รับเหรียญ ASC Coin <br/>(จำกัดสิทธิ์เฉพาะผู้เข้าร่วมงาน)                  
                    </p>
                    </div>
                </div>
                <br/>
                <button className='btn btn-action' onClick={getApiEvent}>เช็ครายชื่อ</button>
                <div className="col-md-12">
                        <table className="table text-white">
                                    <thead>
                                    <tr>
                                        <th scope="col">วันที่ลงทะเบียน</th>
                                        <th scope="col">ชื่อ-นามสกุล</th>
                                        <th scope="col">อีเมล์</th>
                                        <th scope="col">เบอร์โทร</th>
                                        <th scope="col">Wallet</th>
                                        <th scope="col">get free</th>                                        
                                    </tr>
                                    </thead>
                                    <tbody>    
                                        {listAirdrop.map((val,key)=>{
                                            return(
                                                <tr key={key}>
                                                <th scope="col">{format(val.time, 'dd/MM/yyyy, [hh:mm]')}</th>
                                                <th scope="col">{val.name}</th>
                                                <th scope="col">{val.email}</th>
                                                <th scope="col">{val.phone}</th>
                                                <th scope="col">{val.wallet}</th>
                                                <th scope="col">{val.status}</th>                                        
                                            </tr>
                                            )
                                        })}                               
                                   
                                    </tbody>
                        </table>                      
                 
                </div>
                </div>
            </div>
            </section>

        
            
        </div>
    );
}

export default AirdropList;