

import img1 from '../images/icon/Cloud.png'
import img2 from '../images/icon/Wallet.png'
import img3 from '../images/icon/Mining.png'
const dataTrading = [
    {
        id: 1,
        icon: img1,
        step: 'step 1',
        title: 'Download',
        text: 'Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.',
    },
    {
        id: 2,
        icon: img2,
        step: 'step 2',
        title: 'Connect Wallet',
        text: 'Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.',
    },
    {
        id: 3,
        icon: img3,
        step: 'step 3',
        title: 'Start Trading',
        text: 'Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.',
    },
   
]

export default dataTrading;